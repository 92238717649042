<template>
    <el-container class="shopList ">
        <div v-if="!showCamScreen" class="p30">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-col :span="4" class="mr10">
                    <el-form-item size="medium">
                        <el-input
                                placeholder="企业名称"
                                v-model="query.keyword"
                                class="input-with-select"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6" class="tl mr10">
                    <el-form-item size="medium">
                        <el-button
                                type="primary"
                                @click="search"
                                class="general_bgc general_border cfff"
                        >查询</el-button
                        >
                    </el-form-item>
                </el-col>
            </el-form>
            <div >
                <el-table
                        border
                        :data="tableData"
                        style="width: 100%">
                    <el-table-column
                            prop="companyId"
                            label="企业id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            prop="companyName"
                            label="企业名称"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="allowsTheNumber"
                            label="企业人数限制"
                            width="180">
                    </el-table-column>

                    <el-table-column label="企业人数管理" width="200">
                        <template slot-scope="scope">
                            <el-button type="text" class="disib blue" size="mini" @click="handleUpdate(scope.row)">修改</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="i-page fr disib mt20">
                    <el-pagination
                            background
                            :page-size="20"
                            layout="total,prev, pager, next"
                            :total="total"
                            :current-page="currentPage"
                            @current-change="currentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>

        <el-dialog title="企业人数限制设置" :visible.sync="manageVisible" width="35%">
            <div class="df p30" style="justify-content: center;">
                <div class="df" style="align-items: center;margin-right: 50px">
                    企业人数限制
                </div>
                <div>
                    <el-input v-model="number" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div>
                <el-button type="primary" round @click="handleSet">保存</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>
<script>
    import axios from '../../../util/extra-api'
    export default {

        data () {
            return {
                manageVisible:false,
                tableData:[],
                query:{
                    keyword: '',
                    limit: 20,
                    page:1,
                },
                number: 0,
                companyId: null
            }
        },
        mounted () {
            this.getData();
        },
        methods: {
            handleCurrentChange(val) {
                this.currentPage = val
                this.camListQuery.pager_offset = val - 1;
            },

            handleUpdate(row){
                this.number = row.allowsTheNumber
                this.companyId = row.companyId
                this.manageVisible = true
            },
            handleSet(row){
                this.selectedId = row.id
                axios.post("/v1/pc/company/restrict",{companyId: this.companyId,num: this.number}).then((res) => {
                    if(res.code == 200){
                        this.manageVisible = false
                        this.getData()
                    }
                });
            },

            getData(){
                axios.get("/v1/pc/company/list",this.query).then(res=>{
                    if (res.code == 200){
                        this.tableData = res.data.map(m => {
                            return {
                                ...m,
                                allowsTheNumber: m.allowsTheNumber ? m.allowsTheNumber : 5
                            }
                        });
                        this.total = res.total;
                    }
                });
            },
            search(){
                this.getData();
            },


            closeDialogAddgsVisible(){
                this.dialogAddgsVisible = false;
            },
            currentChange(page) {
                this.currentPage = page;
                this.form3.pager_offset = String((page - 1) );
                this.getData();
            },
        }
    }
</script>
<style lang="scss" scoped>
    .shopList {
        overflow-x: hidden;
        .add {
            display: flex;
            top: 0px;
            left: 30px;
            justify-content: space-between;
            align-items: center;
        }
        .active {
            background: #2ec68a;
            border-color: #2ec68a;
            color: #ffffff;
        }
        .list_tab {
            padding: 10px;
            background-color: #f5f7fa;
            .list_tag {
                right: -10px;
                padding: 2px 6px;
                border-radius: 10px;
                top: -10px;
            }
        }
        /deep/ .el-input-group__append .el-button--primary .el-icon-search {
            color: #fff;
        }
        /deep/.el-table .aa .cell {
            display: flex;
            justify-content: center;
        }
    }
    $color: #2194E0;
    .button_hover {
        width: 80px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        text-decoration: none;
        color: #d3d7d4;
        border: 2px solid $color;
        font-size: 14px;
        display: inline-block;
        border-radius: 15px;
        transition: all 0.2s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-right: 10px;
        &:before {
            content: "";
            background-color: rgba(255,255,255,0.5);
            height: 100%;
            width: 3em;
            display: block;
            position: absolute;
            top: 0;
            left: -4.5em;
            transform: skewX(-45deg) translateX(0);
            transition: none;
        }
        &:hover {
            cursor:pointer;
            background-color: $color;
            color: #fff;
            border-bottom: 4px solid darken($color, 10%);
            &:before {
                transform: skewX(-45deg) translateX(13.5em);
                transition: all 0.5s ease-in-out;
            }
        }
    }

    .activated{
        color: #1d953f;
    }
    .haccp-bg {
        transform-origin: 0px 0px 0px;
        background-image: url("../../../assets/image/mapbg.png");
        background-size: cover;
        background-position: 50% 0;
        background-color: rgb(0, 0, 0);
        min-width: auto;
        width: 100%;
        min-height: auto;
        height: 100%;
        overflow: auto;
        overflow-x: hidden;

    }

    .card-item {
        flex: 1;
        width: 31%;
        min-width: 31%;
        max-width: 31%;
        height: 400px;
        background: transparent;
        margin-bottom: 30px;
        border: 0.5px solid aqua;
        border-radius: 6px;
    }
    .card-item-title {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px 20px;
        background: transparent;
        text-align: center;
        font-size: 18px;
        color: #fff;
        border-bottom: 1px solid gainsboro;
    }

    .card-item-content {
        padding: 20px 10px;
        /*height: 20vw*/
    }
</style>
